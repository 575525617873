import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "dashboard",
    type: "item",
    title: "Dashboard",
    translate: "MENU.Dashboard",
    icon: "home",
    url: "/dashboard",
  },
  {
    id: "quick-video-access",
    type: "item",
    title: "Quick Video Access",
    translate: "MENU.QuickVideoAccess",
    icon: "search",
    url: "main/quick-video-access",
  },
  {
    id: "support",
    type: "item",
    title: "Support Chats",
    translate: "MENU.Support",
    icon: "message-square",
    url: "/apps/chat",

  },

  {
    id: "main",
    type: "section",
    title: "Main",
    translate: "MENU.Main",
    icon: "home",
    children: [
      {
        id: "students",
        title: "Students",
        translate: "MENU.STUDENTS",
        type: "item",
        icon: "users",
        url: "main/students",
      },
      {
        id: "teachers",
        title: "Teachers",
        translate: "MENU.Teachers",
        type: "item",
        icon: "user-plus",
        url: "main/teachers",
      },
      {
        id: "influencers",
        title: "Influencers",
        translate: "MENU.Influencers",
        type: "item",
        icon: "user-check",
        url: "main/influencers",
      },
      {
        id: "subscription",
        title: "Subscriptions",
        translate: "MENU.Subscriptions",
        type: "item",
        icon: "dollar-sign",
        url: "main/subscription",

      },
      // {
      //   id: "transactions",
      //   title: "Transactions",
      //   translate: "MENU.Transactions",
      //   type: "item",
      //   icon: "dollar-sign",
      //   url: "main/transactions",
      // },
      {
        id: "devices",
        title: "Devices",
        translate: "MENU.Devices",
        type: "item",
        icon: "tablet",
        url: "main/devices",
      },
      {
        id: "app-version",
        title: "App Versions",
        translate: "MENU.AppVersions",
        type: "item",
        icon: "hexagon",
        url: "main/app-versions",
      },
      {
        id: "payment-methods",
        title: "Payment Methods",
        translate: "MENU.PaymentMethods",
        type: "item",
        icon: "credit-card",
        url: "main/payment-methods",
      },
      {
        id: "coupons",
        title: "Coupons",
        translate: "MENU.Coupons",
        type: "item",
        icon: "percent",
        url: "main/coupons",
      },
      {
        id: "selling-points",
        title: "Selling Points",
        translate: "MENU.SellingPoints",
        type: "item",
        icon: "map-pin",
        url: "main/selling-points",
      },
      {
        id: "video-questions",
        title: "Video Questions",
        translate: "MENU.VideoQuestions",
        type: "item",
        icon: "video",
        url: "main/video-questions",
      },
      {
        id: "videos-url",
        title: "Videos URL",
        translate: "MENU.VideosURL",
        type: "item",
        icon: "link",
        url: "main/videos-url",
      },
    ],
  },
  {
    id: "settings",
    title: "Settings",
    translate: "MENU.Settings",
    type: "section",
    icon: "settings",
    children: [
      {
        id: "admins",
        title: "Admins",
        translate: "MENU.Admins",
        type: "collapsible",
        icon: "user-plus",
        children: [
          {
            id: "admins-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/admins",
          },
          {
            id: "admins-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-admin",
          },
        ],
      },

      {
        id: "avatars",
        title: "Avatars",
        translate: "MENU.Avatars",
        type: "collapsible",
        icon: "image",
        children: [
          {
            id: "avatars-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/avatars",
          },
          {
            id: "avatars-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-avatar",
          },
        ],
      },
      {
        id: "countries",
        title: "Countries",
        translate: "MENU.Countries",
        type: "collapsible",
        icon: "flag",
        children: [
          {
            id: "countries-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/countries",
          },
          {
            id: "countries-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-countries",
          },
        ],
      },
      {
        id: "onboardings",
        title: "Onboardings",
        translate: "MENU.Onboardings",
        type: "collapsible",
        icon: "sliders",
        children: [
          {
            id: "onboardings-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/onboardings",
          },
          {
            id: "onboardings-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-onboarding",
          },
        ],
      },
      {
        id: "levels",
        title: "Levels",
        translate: "MENU.Levels",
        type: "collapsible",
        icon: "bar-chart-2",
        children: [
          {
            id: "levels-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/levels",
          },
          {
            id: "levels-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-levels",
          },
        ],
      },
      {
        id: "school_types",
        title: "School Types",
        translate: "MENU.SchoolTypes",
        type: "collapsible",
        icon: "book-open",
        children: [
          {
            id: "school_types-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/schoolTypes",
          },
          {
            id: "school_types-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-school-types",
          },
        ],
      },
      {
        id: "subjects",
        title: "Subjects",
        translate: "MENU.Subjects",
        type: "collapsible",
        icon: "book",
        children: [
          {
            id: "subjects-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/subjects",
          },
          {
            id: "subjects-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-subject",
          },
        ],
      },
      {
        id: "units",
        title: "Units",
        translate: "MENU.Units",
        type: "collapsible",
        icon: "bookmark",
        children: [
          {
            id: "units-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/units",
          },
          {
            id: "units-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-unit",
          },
        ],
      },
      {
        id: "quizzes",
        title: "Quizzes",
        translate: "MENU.Quizzes",
        type: "collapsible",
        icon: "file-text",
        children: [
          {
            id: "quizzes-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/quizzes",
          },
          {
            id: "quizzes-results",
            title: "Results",
            translate: "MENU.Results",
            type: "item",
            icon: "circle",
            url: "settings/quizzes-results",
          },
          {
            id: "quizzes-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-quiz",
          },
        ],
      },
      {
        id: "flashcard-sections",
        title: "Flashcard Sections",
        translate: "MENU.FlashcardSections",
        type: "collapsible",
        icon: "check-square",
        children: [
          {
            id: "flashcard-sections-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/flashcard-sections",
          },
          {
            id: "flashcard-sections-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-flashcard-section",
          },
        ],
      },
      {
        id: "notes",
        title: "Documents",
        translate: "MENU.Documents",
        type: "collapsible",
        icon: "paperclip",
        children: [
          {
            id: "notes-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/notes",
          },
          {
            id: "notes-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-note",
          },
        ],
      },
      // {
      //   id: "questions",
      //   title: "Questions",
      //   translate: "MENU.SETTINGS.QUESTIONS.COLLAPSIBLE",
      //   type: "collapsible",
      //   icon: "help-circle",
      //   children: [
      //     {
      //       id: "questions-list",
      //       title: "List",
      //       translate: "MENU.SETTINGS.QUESTIONS.LIST",
      //       type: "item",
      //       icon: "circle",
      //       url: "settings/questions",
      //     },
      //     {
      //       id: "questions-add",
      //       title: "Add",
      //       translate: "MENU.SETTINGS.QUESTIONS.ADD",
      //       type: "item",
      //       icon: "circle",
      //       url: "settings/add-question",
      //     },
      //   ],
      // },
      {
        id: "topics",
        title: "Topics",
        translate: "MENU.Topics",
        type: "collapsible",
        icon: "list",
        children: [
          {
            id: "topics-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/topics",
          },
          {
            id: "topics-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-topic",
          },
        ],
      },
      {
        id: "videos",
        title: "Videos",
        translate: "MENU.Videos",
        type: "collapsible",
        icon: "video",
        children: [
          {
            id: "videos-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/videos",
          },
          {
            id: "videos-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-video",
          },
        ],
      },
      {
        id: "events",
        title: "Events",
        translate: "MENU.Events",
        type: "collapsible",
        icon: "calendar",
        children: [
          {
            id: "events-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/events",
          },
          {
            id: "events-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-event",
          },
        ],
      },
      {
        id: "categories",
        title: "Categories",
        translate: "MENU.Categories",
        type: "collapsible",
        icon: "grid",
        children: [
          {
            id: "categories-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/categories",
          },
          {
            id: "categories-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-category",
          },
        ],
      },
      {
        id: "packages",
        title: "Packages",
        translate: "MENU.Packages",
        type: "collapsible",
        icon: "gift",
        children: [
          {
            id: "packages-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/packages",
          },
          {
            id: "packages-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-package",
          },
        ],
      },
      {
        id: "bundles",
        title: "Bundles",
        translate: "MENU.Bundles",
        type: "collapsible",
        icon: "package",
        children: [
          {
            id: "bundles-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/bundles",
          },
          {
            id: "bundles-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-bundle",
          },
        ],
      },
      {
        id: "branches",
        title: "Branches",
        translate: "MENU.Branches",
        type: "collapsible",
        icon: "shuffle",
        children: [
          {
            id: "branches-list",
            title: "List",
            translate: "MENU.List",
            type: "item",
            icon: "circle",
            url: "settings/branches",
          },
          {
            id: "branches-add",
            title: "Add",
            translate: "MENU.Add",
            type: "item",
            icon: "circle",
            url: "settings/add-branches",
          },
        ],
      },
      {
        id: "terms_and_conditions",
        title: "Terms & Conditions",
        translate: "MENU.TermsAndConditions",
        type: "item",
        icon: "file-text",
        url: "settings/terms-and-conditions",
        // children: [
        //   {
        //     id: "terms_and_conditions-list",
        //     title: "List",
        //     translate: "MENU.List",
        //     type: "item",
        //     icon: "circle",
        //     url: "settings/terms-and-conditions",
        //   },
        // {
        //   id: "terms_and_conditions-add",
        //   title: "Add",
        //   translate: "MENU.Add",
        //   type: "item",
        //   icon: "circle",
        //   url: "settings/add-terms-and-conditions",
        // },
        // ],
      },
      // {
      //   id: "privacy_policy",
      //   title: "Privacy Policy",
      //   translate: "MENU.PrivacyPolicy",
      //   type: "collapsible",
      //   icon: "lock",
      //   children: [
      //     {
      //       id: "privacy_policy-list",
      //       title: "List",
      //       translate: "MENU.List",
      //       type: "item",
      //       icon: "circle",
      //       url: "settings/privacy-policy",
      //     },
      //     {
      //       id: "privacy_policy-add",
      //       title: "Add",
      //       translate: "MENU.Add",
      //       type: "item",
      //       icon: "circle",
      //       url: "settings/add-privacy-policy",
      //     },
      //   ],
      // },
      // {
      //   id: "faq",
      //   title: "FAQs",
      //   translate: "MENU.FAQs",
      //   type: "collapsible",
      //   icon: "help-circle",
      //   children: [],
      // },
    ],
  },
];
