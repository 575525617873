import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "app/auth/service";

@Injectable({
  providedIn: "root",
})
export class ApisService {
  private domain = environment.productionDomain;
  private token = "";

  constructor(
    private http: HttpClient,
    private router: Router,
    private _authenticationService: AuthenticationService
  ) {
    this._authenticationService.tokenSubject.subscribe((res: any) => {
      this.token = res;
      // console.log("token form apis", res);
      // console.log("type of token ", typeof this.token);
      return res;
    });
  }

  private createHeaders(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-Language": "en",
      Authorization: `Bearer ${this.token}`,
    });
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401 || error.error.message === "Unauthenticated.") {
      // Redirect to login page on unauthenticated error
      this.router.navigate(["/auth/login"]);
    }
    // You can add more error handling logic here
    return throwError(error);
  }

  Login(email: string, password: string): Observable<any> {
    const headers = this.createHeaders();
    return this.http
      .post(
        `${this.domain}/api/dashboard-api/login`,
        { email, password },
        { headers }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  private authenticatedGet(url: string): Observable<any> {
    const headers = this.createHeaders();
    return this.http
      .get(url, { headers })
      .pipe(catchError(this.handleError.bind(this)));
  }

  private authenticatedPut(url: string, data: any): Observable<any> {
    let headers = this.createHeaders();

    // Remove the 'Content-Type' header if sending FormData
    if (data instanceof FormData) {
      headers = headers.delete("Content-Type");
    }

    return this.http
      .put(url, data, { headers })
      .pipe(catchError(this.handleError.bind(this)));
  }

  private authenticatedPost(url: string, data: any): Observable<any> {
    let headers = this.createHeaders();

    // Remove the 'Content-Type' header if sending FormData
    if (data instanceof FormData) {
      headers = headers.delete("Content-Type");
    }

    return this.http
      .post(url, data, { headers })
      .pipe(catchError(this.handleError.bind(this)));
  }

  uploadVideo(formData: FormData): Observable<any> {
    let headers = this.createHeaders();
    headers = headers.delete("Content-Type");
    const url = `${this.domain}/api/dashboard-auth-api/video-upload`;

    return this.http
      .post(url, formData, {
        reportProgress: true,
        observe: "events",
        headers: headers,
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  SchoolTypes(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/school-types`
    );
  }

  levels(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/levels`
    );
  }

  levelById(levelId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/levels/${levelId}`
    );
  }

  updateLevels(formData: any, levelId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/levels/${levelId}?_method=PUT`,
      formData
    );
  }

  levelsBranches(levelId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/level-branches`,
      { level_id: levelId }
    );
  }

  levelsSubjects(levelId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/level-subjects`,
      { level_id: levelId }
    );
  }

  toggleLevelVisibility(levelId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/toggle-visibility-for-level`,
      { level_id: levelId }
    );
  }

  togglePackageVisibility(packageId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/toggle-visibility-for-package`,
      { package_id: packageId }
    );
  }

  /**
   * Subjects APIS.
   * -----------------------------------------------------------------------------
   */

  Subjects(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/subjects`
    );
  }

  subjectById(subjectId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/subjects/${subjectId}`
    );
  }

  addSubject(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/subjects`,
      formData
    );
  }

  updateSubject(formData: any, subjectId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/subjects/${subjectId}?_method=PUT`,
      formData
    );
  }

  removeSubject(subjectId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/subjects/${subjectId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  addSubjectToLevel(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/add-subject-to-level`,
      formData
    );
  }

  removeSubjectFromLevel(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/remove-level-subject`,
      formData
    );
  }

  toggleSubjectVisibility(subjectId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/toggle-visibility-for-subject`,
      { subject_id: subjectId }
    );
  }

  /**
   * End of subjects APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Units APIS.
   * -----------------------------------------------------------------------------
   */

  Units(): Observable<any> {
    return this.authenticatedGet(`${this.domain}/api/dashboard-auth-api/units`);
  }

  unitById(unitId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/units/${unitId}`
    );
  }

  addUnit(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/units`,
      formData
    );
  }

  updateUnit(formData: any, unitId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/units/${unitId}?_method=PUT`,
      formData
    );
  }

  removeUnit(unitId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/units/${unitId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  levelSubjectUnits(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/units-by-level-subject`,
      formData
    );
  }

  unitTopics(unitId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/topics-by-unit-id`,
      { unit_id: unitId }
    );
  }

  toggleUnitVisibility(unitId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/units/toggle-visibility`,
      { unit_id: unitId }
    );
  }

  /**
   * End of units APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Videos APIS.
   * -----------------------------------------------------------------------------
   */

  Videos(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/videos`
    );
  }

  videoById(videoId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/videos/${videoId}`
    );
  }

  addVideo(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/videos`,
      formData
    );
  }

  updateVideo(formData: any, videoId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/videos/${videoId}?_method=PUT`,
      formData
    );
  }

  removeVideo(videoId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/videos/${videoId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  topicVideos(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/videos-by-topic`,
      formData
    );
  }

  videoQuestions(videoId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/questions-for-video`,
      { video_id: videoId }
    );
  }

  deleteVideoQuestion(videoQuestionId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/remove-video-question-by-id`,
      { video_question_id: videoQuestionId }
    );
  }

  toggleVideoVisibility(videoId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/videos/toggle-visibility`,
      { video_id: videoId }
    );
  }

  removeVideoQuestionReply(replyId: number): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/remove-video-question-reply-by-id`,
      { video_question_reply_id: replyId }
    );
  }

  updateVideoQuestionReply(replyId: number, reply: string): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/update-video-question-reply-by-id`,
      {
        reply: reply,
        video_question_reply_id: replyId,
      }
    );
  }
  /**
   * End of Videos APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Packages APIS.
   * -----------------------------------------------------------------------------
   */

  Packages(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/packages`
    );
  }

  packageById(packageId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/packages/${packageId}`
    );
  }

  addPackage(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/packages`,
      formData
    );
  }

  updatePackage(formData: any, packageId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/packages/${packageId}?_method=PUT`,
      formData
    );
  }

  packagesByLevel(levelId: string): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/packages-by-level`,
      { level_id: levelId }
    );
  }

  removePackage(packageId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/packages/${packageId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  packageSubjects(packageId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/packages/${packageId}/subjects`
    );
  }

  addSubjectToPackage(packageId: any, subjectId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/packages/${packageId}/subjects?subject_id=${subjectId}`,
      {}
    );
  }

  removeSubjectFromPackage(packageId: any, subjectId: any): Observable<any> {
    return this.http
      .delete(
        `${this.domain}/api/dashboard-auth-api/packages/${packageId}/subjects?subject_id=${subjectId}`,
        {
          headers: this.createHeaders(),
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * End of Packages APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Categories APIS.
   * -----------------------------------------------------------------------------
   */

  Categories(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/categories`
    );
  }

  categoryById(categoryId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/categories/${categoryId}`
    );
  }

  addCategory(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/categories`,
      formData
    );
  }

  updateCategory(formData: any, categoryId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/categories/${categoryId}?_method=PUT`,
      formData
    );
  }

  removeCategory(categoryId: any): Observable<any> {
    return this.http
      .delete(
        `${this.domain}/api/dashboard-auth-api/categories/${categoryId}`,
        {
          headers: this.createHeaders(),
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * End of Categories APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Bundles APIS.
   * -----------------------------------------------------------------------------
   */

  Bundles(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/bundles`
    );
  }

  bundleById(bundleId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/bundles/${bundleId}`
    );
  }

  addBundle(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/bundles`,
      formData
    );
  }
  addVideoQuestionReply(payload: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/add-video-question-reply`,
      payload // Send the plain object as the payload
    );
  }

  updateBundle(formData: any, bundleId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/bundles/${bundleId}?_method=PUT`,
      formData
    );
  }

  removeBundle(bundleId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/bundles/${bundleId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  bundelsByPackage(packageId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/bundles-by-package`,
      { package_id: packageId }
    );
  }

  addBundleToPackage(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/add-bundle-to-package`,
      formData
    );
  }

  removeBundleFromPackage(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/remove-bundle-from-package`,
      formData
    );
  }

  /**
   * End of Bundles APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Info APIS.
   * -----------------------------------------------------------------------------
   */

  infoByPackage(packageId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/package-infos-for-package`,
      { package_id: packageId }
    );
  }

  addInfoToPackage(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/package-infos`,
      formData
    );
  }

  removeInfoFromPackage(infoId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/package-infos/${infoId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * End of Info APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Payment Methods APIS.
   * -----------------------------------------------------------------------------
   */

  PaymentMethods(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/payment-methods`
    );
  }

  paymentMethodById(paymentMethodId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/payment-methods/${paymentMethodId}`
    );
  }

  updatePaymentMethod(formData: any, paymentMethodId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/payment-methods/${paymentMethodId}?_method=PUT`,
      formData
    );
  }

  /**
   * End of Payment Methods APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Selling Points APIS.
   * -----------------------------------------------------------------------------
   */

  SellingPoints(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/point-of-sales`
    );
  }
  videoQuestionss(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/video-questions`
    );
  }
  videoQuestionById(id: number): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/video-question-by-id`,
      { video_question_id: id }
    );
  }
  addSellingPoint(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/point-of-sales`,
      formData
    );
  }

  removeSellingPoint(sellingPointId: any): Observable<any> {
    return this.http
      .delete(
        `${this.domain}/api/dashboard-auth-api/point-of-sales/${sellingPointId}`,
        {
          headers: this.createHeaders(),
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateSellingPoint(formData: any, sellingPointId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/point-of-sales/${sellingPointId}?_method=PUT`,
      formData
    );
  }
  /**
   * End of Selling Points APIS.
   * -----------------------------------------------------------------------------
   */
  /**
   * Quizzes APIS.
   * -----------------------------------------------------------------------------
   */

  Quizzes(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/quizzes`
    );
  }

  quizzesResults(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/users-quiz-result`
    );
  }

  quizResultByStudentId(studentId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/user-quizs-result`,
      { user_id: studentId }
    );
  }

  quizById(quizId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/quizzes/${quizId}`
    );
  }

  addQuiz(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/quizzes`,
      formData
    );
  }

  updateQuiz(formData: any, quizId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/quizzes/${quizId}?_method=PUT`,
      formData
    );
  }

  removeQuiz(quizId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/quizzes/${quizId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  quizQuestions(quizId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/questions-for-quiz/${quizId}`
    );
  }

  removeAnswer(answerId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/answers/${answerId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  questionAnswers(questionId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/answers-for-question/${questionId}`
    );
  }

  addAnswer(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/answers`,
      formData
    );
  }

  /**
   * End of Quizzes APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Quiz APIS.
   * -----------------------------------------------------------------------------
   */

  Questions(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/questions`
    );
  }

  questionById(questionId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/questions/${questionId}`
    );
  }

  updateQuestion(formData: any, questionId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/questions/${questionId}?_method=PUT`,
      formData
    );
  }

  removeQuestion(questionId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/questions/${questionId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  addQuestionToQuiz(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/questions`,
      formData
    );
  }

  quizInstructions(quizId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/instructions-for-quiz-by-id`,
      { quiz_id: quizId }
    );
  }

  addInstructionToQuiz(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/instructions`,
      formData
    );
  }

  removeInstruction(instructionId: any): Observable<any> {
    return this.http
      .delete(
        `${this.domain}/api/dashboard-auth-api/instructions/${instructionId}`,
        {
          headers: this.createHeaders(),
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * End of Quiz APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Flashcards APIS.
   * -----------------------------------------------------------------------------
   */
  flashcardsSections(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/flashcard-sections`
    );
  }

  flashcardsSectionById(flashcardsSectionId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/flashcard-sections/${flashcardsSectionId}`
    );
  }

  addFlashcardsSection(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/flashcard-sections`,
      formData
    );
  }

  updateFlashcardsSection(
    formData: any,
    flashcardsSectionId: any
  ): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/flashcard-sections/${flashcardsSectionId}?_method=PUT`,
      formData
    );
  }

  removeFlashcardsSection(flashcardsSectionId: any): Observable<any> {
    return this.http
      .delete(
        `${this.domain}/api/dashboard-auth-api/flashcard-sections/${flashcardsSectionId}`,
        {
          headers: this.createHeaders(),
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  flashcardsBySection(flashcardsSectionId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/flashcards-by-flashcard-section`,
      { flashcard_section_id: flashcardsSectionId }
    );
  }

  addFlashcardToSection(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/flashcards`,
      formData
    );
  }

  flashcardById(flashcardId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/flashcards/${flashcardId}`
    );
  }

  updateFlashcard(formData: any, flashcardId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/flashcards/${flashcardId}?_method=PUT`,
      formData
    );
  }

  removeFlashcard(flashcardId: any): Observable<any> {
    return this.http
      .delete(
        `${this.domain}/api/dashboard-auth-api/flashcards/${flashcardId}`,
        {
          headers: this.createHeaders(),
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  flashcardsSectionsByLevelSubject(levelSubjectId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/flashcard-sections-by-level_subject`,
      { level_subject_id: levelSubjectId }
    );
  }
  /**
   * End of Flashcards APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Admins APIS.
   * -----------------------------------------------------------------------------
   */
  Admins(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/admins`
    );
  }

  addAdmin(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/admins`,
      formData
    );
  }

  updateAdmin(formData: any, adminId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/admins/${adminId}?_method=PUT`,
      formData
    );
  }

  removeAdmin(adminId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/admins/${adminId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  adminById(adminId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/admins/${adminId}`
    );
  }
  /**
   * End of Admins APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * School Types APIS.
   * -----------------------------------------------------------------------------
   */

  addSchoolTypes(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/school-types`,
      formData
    );
  }

  schoolTypeById(schoolTypeId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/school-types/${schoolTypeId}`
    );
  }

  updateSchoolTypes(formData: any, schoolTypeId: any): Observable<any> {
    console.log(schoolTypeId, formData);
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/school-types/${schoolTypeId}?_method=PUT`,
      formData
    );
  }

  addLevels(data: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/levels`,
      data
    );
  }

  // addSubjects(data: FormData): Observable<any> {
  //   return this.authenticatedPost(
  //     `${this.domain}/api/dashboard-auth-api/subjects`,
  //     data
  //   );
  // }

  removeSchoolType(typeId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/school-types/${typeId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  removeLevel(levelId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/levels/${levelId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * Avatars APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Retrieves a list of avatars from the dashboard-auth-api.
   *
   * @return {Observable<any>} An observable containing the list of avatars.
   */
  avatars(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/avatars`
    );
  }

  /**
   * Adds a new avatar to the dashboard-auth-api.
   *
   * @param {any} formData - The form data containing the avatar information.
   * @return {Observable<any>} An observable containing the result of the avatar addition.
   */
  addAvatars(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/avatars`,
      formData
    );
  }

  /**
   * Removes an avatar from the system.
   *
   * @param {any} avatarId - The ID of the avatar to be removed.
   * @return {Observable<any>} An observable containing the result of the remove avatar operation.
   */
  removeAvatar(avatarId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/avatars/${avatarId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  avatarById(avatarId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/avatars/${avatarId}`
    );
  }

  updateAvatars(formData: any, avatarId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/avatars/${avatarId}?_method=PUT`,
      formData
    );
  }

  /**
   * Onboarding APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Retrieves onboarding data from the dashboard-auth-api.
   *
   * @return {Observable<any>} An observable containing the onboarding data.
   */
  onboarding(): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/onboarding`,
      {}
    );
  }

  onboardingById(onboardingId): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/onboarding/${onboardingId}`
    );
  }

  /**
   * Adds a new onboarding to the system.
   *
   * @param {any} formData - The data for the new onboarding.
   * @return {Observable<any>} An observable containing the result of the add onboarding operation.
   */
  addOnboardings(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/add-onboarding`,
      formData
    );
  }

  updateOnboardings(formData: any, boradingId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/onboarding/${boradingId}`,
      formData
    );
  }

  /**
   * Removes an onboarding from the system.
   *
   * @param {any} onboardingId - The ID of the onboarding to be removed.
   * @return {Observable<any>} An observable containing the result of the remove onboarding operation.
   */
  removeOnboarding(onboardingId: any): Observable<any> {
    return this.http
      .delete(
        `${this.domain}/api/dashboard-auth-api/onboarding/${onboardingId}`,
        { headers: this.createHeaders() }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * End of onboarding APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Countries APIS.
   * -----------------------------------------------------------------------------
   */
  /**
   * Retrieves a list of countries.
   *
   * @return {Observable<any>} An observable containing the list of countries
   */

  countries(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/countries`
    );
  }
  /**
   * Adds a new country to the system.
   *
   * @param {any} formData - The country data to be added.
   * @return {Observable<any>} The result of the country addition operation.
   */
  addCountry(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/countries`,
      formData
    );
  }
  postCountries(): Observable<any> {
    const authToken = localStorage.getItem("matlop_token");
    if (!authToken) {
      throw new Error("No authentication token found");
    }

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    });

    return this.http.get(`${this.domain}/api/dashboard-auth-api/countries`, {
      headers,
    });
  }

  updateCountry(formData: any, countryId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/countries/${countryId}?_method=PUT`,
      formData
    );
  }

  /**
   * Removes a country from the system.
   *
   * @param {any} countryId - The ID of the country to be removed.
   * @return {Observable<any>} The result of the country removal operation.
   */
  removeCountry(countryId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/countries/${countryId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * Edits a country in the system.
   *
   * @param {any} formData - The updated country data.
   * @param {any} countryId - The ID of the country to be edited.
   * @return {Observable<any>} The result of the country edit operation.
   */
  editCountry(formData: any, countryId: any): Observable<any> {
    return this.http
      .put(
        `${this.domain}/api/dashboard-auth-api/countries/${countryId}`,
        formData,
        { headers: this.createHeaders() }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }
  /**
   * Retrieves a country by its ID.
   *
   * @param {any} countryId - The ID of the country to be retrieved.
   * @return {Observable<any>} An observable containing the country data.
   */
  countryById(countryId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/countries/${countryId}`
    );
  }

  /**
   * End of Countries APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Branches APIS.
   * -----------------------------------------------------------------------------
   */

  branches(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/branches`
    );
  }

  addBranches(formData: any): Observable<any> {
    console.log(formData);
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/branches`,
      formData
    );
  }

  updateBranches(formData: any, branchId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/branches/${branchId}?_method=PUT`,
      formData
    );
  }

  removeBranches(branchId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/branches/${branchId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  branchById(branchId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/branches/${branchId}`
    );
  }

  toggleBranchVisibility(branchId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/toggle-visibility-for-branch`,
      { branch_id: branchId }
    );
  }

  /**
   * End of Branches APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Students APIS.
   * -----------------------------------------------------------------------------
   */
  students(): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/students`,
      {}
    );
  }

  studentDevices(studentId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/student-devices`,
      { user_id: studentId }
    );
  }

  studentSubscription(studentId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/user-subscriptions-by-id`,
      { user_id: studentId }
    );
  }

  removeStudent(studentId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/students/${studentId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * End of Students APIS.
   * -----------------------------------------------------------------------------
   */

  // subscriptions

  subscriptions(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/subscriptions`
    );
  }

  // Transactions

  transactions(studentId: any): Observable<any> {
    return this.authenticatedPost(`${this.domain}/api/auth-api/check-payment`, {
      user_id: studentId,
    });
  }

  /**
   * Topics APIS.
   * -----------------------------------------------------------------------------
   */

  topics(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/topics`
    );
  }

  toggleTopicVisibility(topic_id: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/topics/toggle-visibility`,
      { topic_id }
    );
  }

  topicById(topicId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/topics/${topicId}`
    );
  }

  addTopic(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/topics`,
      formData
    );
  }

  updateTopic(formData: any, topicId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/topics/${topicId}?_method=PUT`,
      formData
    );
  }

  removeTopic(topicId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/topics/${topicId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  levelSubjectTopics(levelId: any, subjectId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/topics-by-level-subject`,
      { level_id: levelId, subject_id: subjectId }
    );
  }

  /**
   * End of topics APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Notes APIS.
   * -----------------------------------------------------------------------------
   */

  notes(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/documents`
    );
  }

  noteById(noteId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/documents/${noteId}`
    );
  }

  addNote(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/documents`,
      formData
    );
  }

  updateNote(formData: any, noteId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/documents/${noteId}?_method=PUT`,
      formData
    );
  }

  removeNote(noteId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/documents/${noteId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }
  /**
   * End of Notes APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Devices APIS.
   * -----------------------------------------------------------------------------
   */

  devices(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/devices`
    );
  }
  /**
   * End of devices APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * App Version APIS.
   * -----------------------------------------------------------------------------
   */

  appVersions(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/app-versions`
    );
  }

  addAppVersion(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/app-versions`,
      formData
    );
  }

  removeAppVersion(appVersionId: any): Observable<any> {
    return this.http
      .delete(
        `${this.domain}/api/dashboard-auth-api/app-versions/${appVersionId}`,
        {
          headers: this.createHeaders(),
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * End of App Version APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Coupons APIS.
   * -----------------------------------------------------------------------------
   */
  coupons(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/coupons`
    );
  }

  couponById(couponId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/coupons/${couponId}`
    );
  }

  addCoupon(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/coupons`,
      formData
    );
  }

  updateCoupon(formData: any, couponId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/coupons/${couponId}?_method=PUT`,
      formData
    );
  }

  removeCoupon(couponId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/coupons/${couponId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  couponStudents(couponId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/coupon-students`,
      { coupon_id: couponId }
    );
  }
  /**
   * End of Coupons APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Events APIS.
   * -----------------------------------------------------------------------------
   */

  events(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/events`
    );
  }

  addEvent(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/events`,
      formData
    );
  }

  updateEvent(formData: any, eventId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/events/${eventId}?_method=PUT`,
      formData
    );
  }

  eventById(eventId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/events/${eventId}`
    );
  }

  eventQuestions(eventId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/questions-for-event`,
      { event_id: eventId }
    );
  }

  eventQuestionsByUserId(userId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/user-questions-added-to-event`,
      { user_id: userId }
    );
  }
  removeEvent(eventId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/events/${eventId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }
  /**
   * End of Events APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Teachers APIS.
   * -----------------------------------------------------------------------------
   */
  teachers(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/teachers`
    );
  }

  teacherById(teacherId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/teachers/${teacherId}`
    );
  }

  addTeacher(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/teachers`,
      formData
    );
  }

  updateTeacher(formData: any, teacherId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/teachers/${teacherId}?_method=PUT`,
      formData
    );
  }

  removeTeacher(teacherId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/teachers/${teacherId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }
  /**
   * End of Teachers APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Influencers APIS.
   * -----------------------------------------------------------------------------
   */
  influencers(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/influencers`
    );
  }

  influencerById(influencerId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/influencers/${influencerId}`
    );
  }

  addInfluencer(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/influencers`,
      formData
    );
  }

  updateInfluencer(formData: any, influencerId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/influencers/${influencerId}?_method=PUT`,
      formData
    );
  }

  removeInfluencer(influencerId: any): Observable<any> {
    return this.http
      .delete(
        `${this.domain}/api/dashboard-auth-api/influencers/${influencerId}`,
        {
          headers: this.createHeaders(),
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }
  influencerStudents(influencerId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/influencer-students`,
      { influencer_id: influencerId }
    );
  }

  influencerStats(influencerId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/influencer-stats`,
      { influencer_id: influencerId }
    );
  }
  /**
   * End of Teachers APIS.
   * -----------------------------------------------------------------------------
   */

  removeDevice(deviceId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/devices/${deviceId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  logoutDevice(deviceId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/logout-device`,
      { device_id: deviceId }
    );
  }

  deviceById(deviceId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/devices/${deviceId}`
    );
  }

  studentById(studentId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/student-profile`,
      { user_id: studentId }
    );
  }

  sendNotificationToStudent(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/send-notification-to-student`,
      formData
    );
  }

  sendNotificationToDevice(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/send-student-notification-for-device`,
      formData
    );
  }

  /**
   * User APIS.
   * -----------------------------------------------------------------------------
   */

  user(): Observable<any> {
    return this.authenticatedGet(`${this.domain}/api/auth-api/profile-details`);
  }

  invoice(paymentId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/user-subscribed-package-invoice`,
      { payment_id: paymentId }
    );
  }

  // statistics

  statistics(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/statistics`
    );
  }

  // QR Code

  getQRCode(videoId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/get-random-link`,
      { video_id: videoId }
    );
  }

  generateQRCode(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/generate-random-link`,
      formData
    );
  }

  getFlashcardsQRCode(flashcardsSectionId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/get-flash-link-qr`,
      { flashcard_section_id: flashcardsSectionId }
    );
  }

  generateFlashcardsQRCode(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/generate-flash-link`,
      formData
    );
  }

  terms(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/terms-conditions`
    );
  }

  termsById(termsId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/terms-conditions/${termsId}`
    );
  }

  updateTerms(formData: any, termsId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/terms-conditions/${termsId}?_method=PUT`,
      formData
    );
  }

  updateUnitsOrder(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/update-units-order`,
      formData
    );
  }
  updateVideosOrderApi(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/update-videos-order`,
      formData
    );
  }

  updateTopicsOrder(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/update-topics-order`,
      formData
    );
  }

  updateFlashcardsSectionsOrder(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/flashcard-sections-order`,
      formData
    );
  }

  getWhatsappCustomers(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/whatsapp/customers`
    );
  }

  getWhatsappMessages(phone: string): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/whatsapp/messages/byphone`,
      { phone: phone }
    );
  }

  sendWhatsappMessage(phone: string, message: string) {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/send-whatsapp`,
      { phone: phone, message: message }
    );
  }
}
