<!-- Footer -->
<p class="clearfix mb-0 mx-auto text-center">
  <span class="float-md-center d-block d-md-inline-block mt-25">
    {{ 'MENU.title' | translate }} &copy; 2024
    <span class="m-0 p-0 ml-25 font-weight-bold">{{ 'MENU.app_name' | translate }}</span>
    <span class="d-none d-sm-inline-block">, {{ 'MENU.all_rights_reserved' | translate }}</span>
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
