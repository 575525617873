import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoadingComponent } from './loading/loading.component';



@NgModule({
  declarations: [
    LoadingComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  exports:[
    TranslateModule,
    LoadingComponent
  ],
  providers:[TranslateService]
})
export class SharedModule { }
