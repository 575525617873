<div
  *ngIf="isLoading"
  class="loading-overlay d-flex flex-column justify-content-center align-items-center gap-3 md:gap-5"
>
  <img src="assets/images/Wajad-app/white-logo.svg" alt="Wajad Logo" />
  <p class="text-white fs-3">{{ "MENU.loading" | translate }}</p>
  <div class="spin">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</div>
