export const locale = {
  lang: "ar",
  data: {
    MENU: {
      loading: "جاري التحميل...",
      Main: "الرئيسية",
      DEVICES: "الأجهزة",
      Invoice: "فاتورة",
      eCommerce: "التجارة الإلكترونية",
      AppVersions: "إصدارات التطبيقات",
      PaymentMethods: "طرق الدفع",
      SellingPoints: "نقاط البيع",
      User: "المستخدم",
      Settings: "الإعدادات",
      STUDENTS: "الطلاب المسجلون",
      Subscription: "الاشتراك",
      Transactions: "المعاملات",
      Devices: "الأجهزة",
      "App Versions": "إصدارات التطبيقات",
      "Payment Methods": "طرق الدفع",
      Admins: "المسؤولون",
      List: "قائمة",
      Add: "إضافة",
      Avatars: "الصور الرمزية",
      Countries: "البلدان",
      Onboardings: "التعريفات",
      Levels: "المستويات",
      SchoolTypes: "أنواع المدارس",
      Subjects: "المواد",
      Units: "الوحدات",
      ListOfUnitsInSystem: "قائمة الوحدات في النظام",
      Quizzes: "الاختبارات",
      Results: "النتائج",
      Description: "الوصف",
      ResultsForStudent: "نتائج الطالب",
      ListOfResults: "قائمة النتائج في النظام",
      FlashcardSections: "أقسام البطاقات التعليمية",
      Documents: "المستندات",
      Topics: "المواضيع",
      Videos: "الفيديوهات",
      Events: "الفعاليات",
      Categories: "الفئات",
      Packages: "الباقات",
      Bundles: "الحزم",
      Branches: "الفروع",
      TermsAndConditions: "الشروط والأحكام",
      PrivacyPolicy: "سياسة الخصوصية",
      FAQs: "الأسئلة الشائعة",
      AllAdminsListed: "جميع المشرفين المدرجين في النظام",
      Show: "عرض",
      Entries: "سجلات",
      Search: "بحث",
      Delete: "حذف",
      Edit: "تعديل",
      FirstName: "الاسم الأول",
      EnterFirstName: "أدخل الاسم الأول",
      LastName: "الاسم الأخير",
      EnterLastName: "أدخل الاسم الأخير",
      Country: "الدولة",
      EventType: "نوع الفعالية",
      EnterEventType: "أدخل نوع الفعالية",
      Branch: "الفرع",
      SelectABranch: "اختر فرع",
      SelectCountry: "حدد دولة",
      PleaseSelectCountry: "يرجى اختيار دولة",
      Avatar: "الصورة الرمزية",
      PleaseSelectAvatar: "يرجى اختيار صورة رمزية",
      Email: "البريد الإلكتروني",
      EnterEmail: "أدخل البريد الإلكتروني",
      MobileNumber: "رقم الجوال",
      EnterMobileNumber: "أدخل رقم الجوال",
      Password: "كلمة المرور",
      EnterPassword: "أدخل كلمة المرور",
      ListAllAvatars:
        "قائمة جميع الصور الرمزية التي تظهر للطلاب للاختيار من بينها",
      PleaseFillFields: "يرجى تعبئة جميع الحقول",
      PleaseSelectImage: "يرجى اختيار الصورة",
      ChooseFile: "اختيار ملف",
      SAVE: "حفظ",
      AllCountriesListed: "جميع الدول المدرجة في النظام...",
      Entires: " الإدخالات",
      NameInArabic: "الاسم بالعربية",
      EnterNameInArabic: "أدخل الاسم بالعربية",
      NameInEnglish: "الاسم بالإنجليزية",
      EnterNameInEnglish: "أدخل الاسم بالإنجليزية",
      Code: "الرمز",
      EnterCode: "أدخل الكود",
      MobileCode: "كود الهاتف",
      EnterMobileCode: "أدخل كود الهاتف",
      Image: "صورة",
      MobileLength: "طول الهاتف",
      EnterMobileLength: "أدخل طول الهاتف",
      StartsWith: "يبدأ بـ",
      EnterMobileStartValues: "أدخل قيم بداية الهاتف",
      Onboarding: "التعريف",
      ListOfOnboardingScreens:
        "قائمة شاشات التعريف المعروضة للمستخدم عند فتح التطبيق.",
      PleaseFillAllFields: "يرجى ملء جميع الحقول",
      TitleInArabic: "العنوان باللغة العربية",
      TitleInArabicPlaceholder: "العنوان باللغة العربية...",
      TitleInEnglish: "العنوان باللغة الإنجليزية",
      TitleInEnglishPlaceholder: "العنوان باللغة الإنجليزية...",
      FieldRequired: "هذا الحقل مطلوب!",
      BodyInArabic: "النص باللغة العربية",
      BodyInArabicPlaceholder: "النص باللغة العربية...",
      BodyInEnglish: "النص باللغة الإنجليزية",
      BodyInEnglishPlaceholder: "النص باللغة الإنجليزية...",
      Save: "حفظ",
      AllLevelsDescription: "جميع المستويات المضافة إلى المشروع ...",
      ShowEntries: "إظهار الإدخالات",
      SchoolType: "نوع المدرسة",
      SelectSchoolType: "اختر نوع المدرسة",
      PleaseSelectSchoolType: "يرجى اختيار نوع المدرسة",
      NameInArabicPlaceholder: "الاسم باللغة العربية ...",
      NameInEnglishPlaceholder: "الاسم باللغة الإنجليزية ...",
      AddSubjectToLevel: "إضافة مادة إلى المستوى",
      Subject: "المادة",
      SelectSubject: "اختر مادة",
      PleaseSelectSubject: "يرجى اختيار مادة!",
      FlashcardSectionsInLevel: "أقسام البطاقات التعليمية في المستوى ",
      AndSubject: " والمادة",
      AddFlashcardSection: "إضافة قسم بطاقة تعليمية",
      AllFlashcardSections:
        "جميع أقسام البطاقات التعليمية المضافة إلى هذا المستوى والمادة ...",
      ViewFlashcards: "عرض البطاقات التعليمية",
      SubjectTopics: "موضوعات المادة",
      AddTopic: "إضافة موضوع",
      AllTopics: "جميع الموضوعات المضافة إلى هذه المادة ...",
      LevelBranches: "فروع المستوى",
      AddBranch: "إضافة فرع",
      AllBranches: "جميع الفروع المضافة إلى هذا المستوى ...",
      LevelSubjects: "مواد المستوى",
      AddSubject: "إضافة مادة",
      AllSubjects: "جميع المواد المضافة إلى هذا المستوى ...",
      ViewTopics: "عرض المواضيع",
      DocumentsList: "قائمة المستندات في النظام.",
      Level: "المستوى",
      All: "الكل",
      PleaseSelectDocument: "يرجى اختيار المستند",
      ThisFieldIsRequired: "هذا الحقل مطلوب!",
      SelectALevel: "اختر مستوى",
      SelectASubject: "اختر مادة",
      IsMain: "رئيسي",
      SelectAValue: "اختر قيمة",
      Yes: "نعم",
      No: "لا",
      IsFree: "مجاني",
      DescriptionInArabic: "الوصف بالعربية",
      DescriptionInEnglish: "الوصف بالإنجليزية",
      Title: "أنواع المدارس",
      ListOfSubjects: "قائمة المواضيع في النظام.",
      NameInArabicRequired: "هذه الخانة مطلوبة!",
      NameInEnglishRequired: "هذه الخانة مطلوبة!",
      DescriptionInArabicRequired: "هذه الخانة مطلوبة!",
      DescriptionInEnglishRequired: "هذه الخانة مطلوبة!",
      Instructions: "يرجى ملء جميع الحقول بحرية",
      ListOfQuizzes: "قائمة الاختبارات في النظام.",

      Questions: "الأسئلة",
      QuizInstructionsFor: "تعليمات الاختبار لـ",
      AddInstruction: "إضافة تعليمات",
      AllInstructionsAdded: "جميع التعليمات المضافة إلى هذا الاختبار ...",
      QuestionAnswersFor: "إجابات السؤال لـ",
      AddAnswer: "إضافة إجابة",
      AllAnswersAdded: "جميع الإجابات المضافة إلى هذا السؤال ...",
      AddQuestionToQuiz: "إضافة سؤال إلى الاختبار",
      QuestionText: "نص السؤال ...",
      QuestionTextPlaceholder: "نص السؤال",
      QuestionType: "نوع السؤال",
      SelectQuestionType: "حدد نوع السؤال",
      SeekSeconds: "ثواني البحث ...",
      SeekSecondsPlaceholder: "ثواني البحث",
      SelectVideo: "حدد فيديو",
      Video: "فيديو",
      AddInstructionToQuiz: "إضافة تعليمات إلى الاختبار",
      InstructionTextInArabic: "نص التعليمات بالعربية",
      InstructionTextInEnglish: "نص التعليمات بالإنجليزية",
      PleaseSelectALevel: "يرجى اختيار مستوى",
      SelectASubjectFromThisLevel: "اختر موضوعًا من هذا المستوى",
      PleaseSelectASubject: "يرجى اختيار الموضوع",
      TimeLimitInMinutes: "الوقت المحدد (بالدقائق)...",
      TimeLimit: "الوقت المحدد (بالدقائق)",
      TotalMarks: "إجمالي الدرجات ...",
      PassingMarks: "الحد الأدنى للدرجات ...",
      Price: "السعر",
      Free: "مجاني",
      Paid: "مدفوع",
      SelectAPrice: "اختر السعر",
      AddAnswerToQuestion: "أضف إجابة للسؤال",
      AnswerText: "نص الإجابة",
      IsCorrect: "هل الإجابة صحيحة",
      SelectCorrectAnswer: "اختر الإجابة الصحيحة",
      True: "صحيح",
      False: "خطأ",
      Question: "السؤال",
      Answer: "الإجابة",
      Name: "الاسم",
      SelectLevel: "اختر مستوى",
      PleaseSelectLevel: "يرجى اختيار مستوى!",
      FlashcardsInSection: "بطاقات التعلم في القسم",
      AddFlashcard: "إضافة بطاقة تعلم",
      AllFlashcardsInSection: "جميع بطاقات التعلم في هذا القسم ...",

      Flashcards: "بطاقات التعلم",

      TopicType: "نوع الموضوع ...",
      SelectTopicType: "اختر نوع الموضوع",
      Quiz: "اختبار",
      FieldIsRequired: "هذا الحقل مطلوب!",
      SelectQuiz: "اختر اختبار",

      Duration: "المدة الزمنية",
      TopicVideosFor: "فيديوهات الموضوع لـ",
      ListOfVideosInTopic: "قائمة الفيديوهات في هذا الموضوع.",
      ListOfTopicsInSystem: "قائمة المواضيع في النظام.",
      EnterTitleInArabic: "أدخل العنوان بالعربية",
      EnterTitleInEnglish: "أدخل العنوان بالإنجليزية",
      EnterDescriptionInArabic: "أدخل الوصف بالعربية",
      EnterDescriptionInEnglish: "أدخل الوصف بالإنجليزية",
      Topic: "الموضوع",
      SelectTopic: "اختر موضوعًا",
      EnterDuration: "أدخل المدة",
      SelectPrice: "اختر سعرًا",

      URL: "الرابط",
      EnterURL: "أدخل الرابط",
      ListOfVideos: "قائمة الفيديوهات في النظام",
      ListOfCategories: "قائمة الفئات في النظام.",
      Icon: "الأيقونة",

      PleaseSelectIcon: "يرجى اختيار الأيقونة",
      Category: "الفئة",
      SelectACategory: "اختر فئة",

      PleaseSelectACategory: "يرجى اختيار الفئة",
      AddBundleToPackage: "إضافة حزمة إلى الباقة",
      PleaseSelectABundle: "يرجى اختيار حزمة",
      PriceRequired: "هذه الخانة مطلوبة!",
      PriceCannotBeLessThan0: "لا يمكن أن يكون السعر أقل من 0!",
      Discount: "الخصم (نسبة مئوية %)",
      DiscountRequired: "هذه الخانة مطلوبة!",
      DiscountCannotBeLessThan0: "لا يمكن أن يكون الخصم أقل من 0!",
      Bundle: "الحزمة",
      SelectABundle: "اختر حزمة",
      ArabicDescription: "الوصف بالعربية",
      PleaseAddArabicDescription: "يرجى إضافة الوصف بالعربية!",
      EnglishDescription: "الوصف بالإنجليزية",
      PleaseAddEnglishDescription: "يرجى إضافة الوصف بالإنجليزية!",
      AddInformationToPackage: "إضافة معلومات إلى الحزمة",
      AddSubjectToPackage: "إضافة مادة إلى الحزمة",
      PackageBundlesFor: "حزم الحزمة لـ",
      AddBundle: "إضافة حزمة",
      AllBundlesAddedToThisPackage: "جميع الحزم المضافة إلى هذه الحزمة ...",

      Days: "أيام",
      KWD: "دينار كويتي",
      PackageInformationFor: "معلومات الحزمة لـ",
      AddInfo: "إضافة معلومات",
      AllInformationAddedToThisPackage:
        "جميع المعلومات المضافة إلى هذه الحزمة ...",
      PackageSubjectsFor: "الموضوعات الخاصة بالحزمة لـ",
      AllSubjectsAddedToThisPackage:
        "جميع الموضوعات المضافة إلى هذه الحزمة ...",
      ListOfPackagesInTheSystem: "قائمة الحزم في النظام.",

      Info: "معلومات",

      DurationInDays: "المدة بالأيام",
      DurationCannotBeGreaterThan365Days:
        "لا يمكن أن تكون المدة أكبر من 365 يومًا!",
      ListOfBundles: "قائمة الحزم في النظام",

      LevelID: "معرّف المستوى",
      ListOfBranches: "قائمة الفروع",
      AddSellingPoint: "إضافة نقطة بيع",
      AddNewSellingPointToSystem: "إضافة نقطة بيع جديدة إلى النظام.",
      Latitude: "خط العرض",
      Longitude: "خط الطول",
      ArabicCity: "المدينة بالعربية",
      EnglishCity: "المدينة بالإنجليزية",
      ArabicAddress: "العنوان بالعربية",
      EnglishAddress: "العنوان بالإنجليزية",
      AllSellingPointsInSystem: "جميع نقاط البيع في النظام.",
      EditPaymentMethod: "تعديل طريقة الدفع",
      EditPaymentMethodToSystem: "تعديل طريقة الدفع للنظام.",

      ArabicName: "الاسم بالعربية",
      EnglishName: "الاسم بالإنجليزية",
      UpdatePaymentMethod: "تحديث طريقة الدفع",
      AllPaymentMethodsInSystem: "جميع طرق الدفع في النظام.",
      AddAppVersion: "إضافة إصدار التطبيق",
      AddNewAppVersion: "إضافة إصدار جديد للتطبيق في النظام.",
      VersionNumber: "رقم الإصدار ...",
      Platform: "المنصة",
      SelectPlatform: "اختر المنصة",
      AddAppVersionButton: "إضافة إصدار التطبيق",

      AllAppVersions: "جميع إصدارات التطبيق في النظام.",
      AllDevices: "جميع الأجهزة المدرجة في النظام...",

      Send: "إرسال",
      Notification: "إشعار",
      SendNotification: "إرسال إشعار",
      AllTransactionsListed: "جميع المعاملات المدرجة في النظام...",

      KD: "دينار كويتي",
      Subscriptions: "الاشتراكات",
      AllSubscriptionsListed: "جميع الاشتراكات المدرجة في النظام...",

      ViewInvoice: "عرض الفاتورة",
      app_name: "WAJAD-APP",
      all_rights_reserved: "جميع الحقوق محفوظة",
      title: "حقوق الطبع والنشر",
      LOGOUT: "تسجيل الخروج",
      NAME_IN_ARABIC: "أدخل الاسم بالعربية",
      NAME_IN_ENGLISH: "أدخل الاسم بالإنجليزية",
      CODE: "أدخل الرمز",
      MOBILE_CODE: "أدخل رمز الهاتف المحمول",
      IMAGE: "أدخل الصورة",
      MOBILE_LENGTH: "أدخل طول رقم الهاتف المحمول",
      STARTS_WITH: "أدخل القيم التي يبدأ بها الرقم المحمول",
      ADD_COUNTRY: "إضافة دولة",
      ADD_SUBSCRIPTION_FOR: "إضافة اشتراك لـ",
      PLEASE_FILL_ALL_FIELDS: "يرجى ملء جميع الحقول",
      PACKAGE: "اختر حزمة",
      BUNDLE: "اختر باقة",
      THIS_FIELD_IS_REQUIRED: "هذا الحقل مطلوب!",
      PACKAGEE: "حزمة",
      BUNDLEE: "باقة",
      MESSAGE: "الرسالة",
      SEND: "إرسال",
      MESSAGE_REQUIRED: "هذه الخانة مطلوبة!",
      DEVICES_FOR: "الأجهزة الخاصة بـ",
      ALL_DEVICES_LISTED_FOR_THIS_STUDENT:
        "جميع الأجهزة المدرجة لهذا الطالب...",
      SHOW: "عرض",
      ENTRIES: "الإدخالات",
      SEARCH: "بحث",
      DELETE: "حذف",
      SEND_NOTIFICATION: "إرسال الإشعار",
      ABOUT: "عن",
      LEVEL: "المستوى",
      SCHOOL: "المدرسة",
      BIRTHDATE: "تاريخ الميلاد",
      COUNTRY: "البلد",
      JOINED: "انضم",
      SUBSCRIPTIONS: "الاشتراكات",
      START_DATE: "تاريخ البدء",
      END_DATE: "تاريخ الانتهاء",
      LOGIN_TIME: "وقت تسجيل الدخول",
      LOGOUT_TIME: "وقت تسجيل الخروج",
      LOGGED_IN: "مسجل الدخول",
      ALL_STUDENTS: "  عرض جميع بيانات الطلاب المسجلين في النظام ",
      FILTER_BY_LEVEL: "التصفية حسب المستوى",
      ALL: "الكل",
      PROFILE: "الملف الشخصي",
      QUIZZES: "الاختبارات",
      SUBSCRIPTION: "الاشتراك",
      SUBSCRIPTIONS_FOR: "الاشتراكات لـ",
      ADD_SUBSCRIPTION: "إضافة اشتراك",
      ALL_SUBSCRIPTIONS: "جميع الاشتراكات لهذا الطالب",
      DAYS: "أيام",
      id: "معرّف",
      Mobilee: "رقم الهاتف",
      Birthdate: "تاريخ الميلاد",
      CurrentLevel: "المستوى الحالي",
      Actions: "إجراءات",
      Version: "الإصدار",
      UserName: "اسم المستخدم",
      DeviceModel: "طراز الجهاز",
      Manufacturer: "الشركة المصنعة",
      LoginTime: "وقت الدخول",
      DeviceName: "اسم الجهاز",
      PaymentMethod: "طريقة الدفع",
      Web: "الويب",
      IOS: "آي أو إس",
      Android: "أندرويد",
      City: "المدينة",
      Address: "العنوان",
      Student: "الطالب",
      Score: "الدرجة",
      CorrectAnswers: "الإجابات الصحيحة",
      Passed: "ناجح",
      LoggedStatus: "حالة التسجيل",
      tdMessage: "الرسالة",
      PaymentID: "معرّف الدفع",
      Package: "الباقة",
      Amount: "المبلغ",
      StartDate: "تاريخ البدء",
      EndDate: "تاريخ الانتهاء",
      Status: "الحالة",
      InvoiceID: "رقم الفاتورة",
      StudentName: "اسم الطالب",
      TotalAmount: "المبلغ الإجمالي",
      ID: "الرقم التعريفي",
      Body: "الجسم",
      Instruction: "تعليمات",
      InvoiceDate: "تاريخ الفاتورة",
      From: "من",
      WajadApp: "تطبيق واجد",
      KuwaitAddress: "الكويت، مدينة الكويت، شارع الخليج العربي",
      Item: "السلعة",
      Total: "الإجمالي",
      To: "إلى",
      EVENT_QUESTIONS: "أسئلة الفعالية",
      EventQuestions: "أسئلة الحدث",
      EventQuestionsList: "قائمة أسئلة الحدث للحدث المحدد.",
      EventsList: "قائمة الأحداث في النظام.",
      ViewQuestions: "عرض الأسئلة",
      EventQuestionsAskedBy: "الأسئلة الخاصة بالحدث من",
      EventQuestionsListStudent:
        "قائمة الأسئلة الخاصة بالحدث التي طرحها هذا الطالب.",
      EventName: "اسم الحدث",
      Titlee: "العنوان",

      StartTime: "وقت البدء",
      EndTime: "وقت الانتهاء",
      ViewInstructions: "عرض التعليمات",
      AddReply: "إضافة رد",
      ViewReplies: "عرض الردود",
      AddQuestionReply: "إضافة رد السؤال",
      AddReplyToQuestion: "إضافة رد إلى سؤال الطالب...",
      Reply: "الرد",
      Visibility: "الرؤية",
      Visible: "مرئي",
      Hidden: "مخفي",
      Teachers: "المعلمين",
      AddTeacher: "إضافة معلم",
      AllTeachers: "جميع المعلمين في النظام",
      Desc: "الوصف",
      UploadVideo: "رفع الفيديو",
      UploadVideoBody: "إضافة فيديو جديد للنظام",
      VideoQuestions: "أسئلة الفيديو",
      SelectLevelID: "اختر السنة الدراسية",
      Unit: "الوحدة",
      PleaseSelectUnit: "يرجى اختيار الوحدة",
      ViewUnits: "عرض الوحدات",
      Coupons: "الباقات",
      DiscountValue: "قيمة الخصم",
      ExpiryDate: "تاريخ الإنتهاء",
      UsageLimit: "عدد الاستخدامات",
      MaxDiscountAmount: "الحد الأقصى لقيمة الخصم",
      DiscountType: "نوع الخصم",
      Fixed: "ثابت",
      Percentage: "نسبة مئوية",
      SelectCouponType: "اختر نوع الخصم",
      AddCoupon: "إضافة خصم",
      CouponsList: "قائمة الخصومات في النظام.",
      Active: "مفعل",
      Expired: "منتهي",
      RemainingUses: "الاستخدامات المتبقية",
      SelectUnit: "اختر الوحدة",
      IsFreeTrial: "هل هو اختبار مجاني",
      AddTitle: "إضافة فرع جديد",
      AddBody: "إضافة فرع جديد إلى المشروع.",
      EditTitle: "تعديل الفرع",
      EditBody: "تعديل الفرع الذي تم إضافته مسبقاً.",
      AddTitles: "إضافة حزمة جديدة",
      AddBodys: "إضافة حزمة جديدة إلى المشروع.",
      EditTitles: "تعديل الحزمة",
      EditBodys: "تعديل الحزمة التي تم إضافتها مسبقاً.",
      AddTitless: "إضافة حزمة جديدة",
      AddBodyss: "إضافة حزمة جديدة إلى المشروع.",
      EditTitless: "تعديل الحزمة",
      EditBodyss: "تعديل الحزمة التي تم إضافتها مسبقاً.",
      AddTitlesss: "إضافة فئة جديدة",
      AddBodysss: "إضافة فئة جديدة إلى المشروع.",
      EditTitlesss: "تعديل الفئة",
      EditBodysss: "تعديل الفئة التي تم إضافتها مسبقاً.",
      AddTitlessss: "إضافة حدث جديد",
      AddBodyssss: "إضافة حدث جديد إلى المشروع.",
      EditTitlessss: "تعديل الحدث",
      EditBodyssss: "تعديل الحدث الذي تم إضافته مسبقاً.",
      AddTitlesssss: "إضافة فيديو جديد",
      AddBodysssss: "إضافة فيديو جديد إلى المشروع.",
      EditTitlesssss: "تعديل الفيديو",
      EditBodysssss: "تعديل الفيديو الذي تم إضافته مسبقاً.",
      AddTitleTopic: "إضافة موضوع جديد",
      AddBodyTopic: "إضافة موضوع جديد إلى المشروع.",
      EditTitleTopic: "تعديل الموضوع",
      EditBodyTopic: "تعديل الموضوع الذي تم إضافته مسبقاً.",
      AddTitleNote: "إضافة مستند جديد",
      AddBodyNote: "إضافة مستند جديد إلى المشروع.",
      EditTitleNote: "تعديل المستند",
      EditBodyNote: "تعديل المستند الذي تم إضافته مسبقاً.",
      AddTitleFlashSec: "إضافة قسم بطاقات فلاش جديدة",
      AddBodyFlashSec: "إضافة قسم بطاقات فلاش جديدة إلى المشروع.",
      EditTitleFlashSec: "تعديل قسم بطاقات الفلاش",
      EditBodyFlashSec: "تعديل قسم بطاقات الفلاش الذي تم إضافته مسبقاً.",
      AddTitleFlash: "إضافة بطاقة فلاش جديدة إلى القسم",
      AddBodyFlash: "إضافة بطاقة فلاش جديدة إلى المشروع.",
      EditTitleFlash: "تعديل بطاقة فلاش",
      EditBodyFlash: "تعديل بطاقة الفلاش التي تم إضافتها مسبقاً.",
      AddTitleQuiz: "إضافة اختبار جديد",
      AddBodyQuiz: "إضافة اختبار جديد إلى المشروع.",
      EditTitleQuiz: "تعديل اختبار",
      EditBodyQuiz: "تعديل الاختبار الذي تم إضافته مسبقاً.",
      AddTitleUnit: "إضافة وحدة جديدة",
      AddBodyUnit: "إضافة وحدة جديدة إلى المشروع.",
      EditTitleUnit: "تعديل وحدة",
      EditBodyUnit: "تعديل الوحدة التي تم إضافتها مسبقاً.",
      AddTitleSub: "إضافة مادة جديدة",
      AddBodySub: "إضافة مادة جديدة إلى المشروع.",
      EditTitleSub: "تعديل مادة",
      EditBodySub: "تعديل المادة التي تم إضافتها مسبقاً.",
      AddTitleSchool: "إضافة نوع مدرسة جديد",
      AddBodySchool: "إضافة نوع مدرسة جديدة إلى المشروع.",
      EditTitleSchool: "تعديل نوع مدرسة",
      EditBodySchool: "تعديل نوع المدرسة الذي تم إضافته مسبقاً.",
      AddTitleLevel: "إضافة مستوى جديد",
      AddBodyLevel: "إضافة مستوى جديد إلى المشروع.",
      EditTitleLevel: "تعديل مستوى",
      EditBodyLevel: "تعديل المستوى الذي تم إضافته مسبقاً.",
      AddTitleOnb: "إضافة عملية تأهيل جديدة",
      AddBodyOnb: "إضافة عملية تأهيل جديدة إلى المشروع.",
      EditTitleOnb: "تعديل عملية تأهيل",
      EditBodyOnb: "تعديل عملية التأهيل التي تم إضافتها مسبقاً.",
      AddTitleCount: "إضافة دولة جديدة",
      AddBodyCount: "إضافة دولة جديدة إلى المشروع.",
      EditTitleCount: "تعديل دولة",
      EditBodyCount: "تعديل الدولة التي تم إضافتها مسبقاً.",
      AddTitleAva: "إضافة صورة رمزية جديدة",
      AddBodyAva: "إضافة صورة رمزية جديدة إلى المشروع.",
      EditTitleAva: "تعديل صورة رمزية",
      EditBodyAva: "تعديل الصورة الرمزية التي تم إضافتها مسبقاً.",
      AddTitleAd: "إضافة مدير جديد",
      AddBodyAd: "إضافة مدير جديد إلى المشروع.",
      EditTitleAd: "تعديل مدير",
      EditBodyAd: "تعديل المدير الذي تم إضافته مسبقاً.",
      Addvid: "إضافة سؤال فيديو",
      Addvideo: "إضافة فيديو",
      Replyvid: "إضافة رد على سؤال الفيديو",
      video_questions: "أسئلة الفيديو",
      add_video_question: "إضافة سؤال فيديو",
      all_video_questions: "جميع أسئلة الفيديو في النظام",
      student_name: "اسم الطالب",
      EditCoupon: "تعديل القسيمة",
      EditCouponAdd: "قم بتعديل القسيمة التي تمت إضافتها من قبل.",
      AddCouponProject: "أضف قسيمة جديدة إلى المشروع.",
      VideoQuestFor: "أسئلة الفيديو لـ",
      listOfQuest: "قائمة الأسئلة للفيديو",
      start_date: "تاريخ البدء",
      end_date: "تاريخ الانتهاء",
      add_event: "إضافة حدث",
      live: "مباشر",
      test: "اختبار",
      KeepVideo: "ابق الفيديو الحالي",
      TeacherOptional: "المعلم (اختياري)",
      SelectTeacher: "اختر معلم",
      EditTeacher: "تعديل معلم",
      EditTeacherBody: "تعديل معلم الذي تم إضافته مسبقاً.",
      AddTeacherBody: "إضافة معلم جديد إلى المشروع.",
      SelectACountry: "اختر دولة",
      PleaseSelectACountry: "يرجى اختيار دولة",
      Phone: "رقم الهاتف",
      EnterPhone: "أدخل رقم الهاتف",
      PleaseEnterAValidEmail: "يرجى إدخال بريد إلكتروني صالح",
      ReferralCode: "رمز التوصية",
      ReferredStudents: "الطلاب المرتبطين",
      ViewStats: "الإحصائيات",
      ReferredStudentsBy: "الطلاب المرتبطين بالمعلم: ",
      AllReferredStudents: "جميع الطلاب المرتبطين في النظام لهذا المعلم.",
      TotalDiscount: "إجمالي الخصم",
      TotalEarnings: "إجمالي الإيرادات",
      Students: "الطلاب",
      Revenue: "الإيرادات",
      Statistics: "الإحصائيات",
      Available: "متاح",
      Used: "مستخدم",
      BestSellerBundles: "أفضل الباقات مبيعا",
      BasedOnSubscriptions: "بناءً على عدد الاشتراكات",
      BundleDiscount: "خصم الباقة",
      CouponDiscount: "خصم الكوبون",
      Dashboard: "الرئيسية",
      AddQuestion: "إضافة سؤال",
      Answers: "الإجابات",
      VideoURLEmpty: "قم باحذف الحقل لتحميل فيديو جديد.",
      Back: "العودة",
      UploadComplete: "تم تحميل الفيديو بنجاح",
      UploadFailed: "فشل تحميل الفيديو",
      UploadFailedMsg: "فشل تحميل الفيديو",
      UploadCompleteMsg: "تم تحميل الفيديو بنجاح",
      QuickVideoAccess: "الوصول إلى الفيديو",
      AddVideoToTopic: "إضافة فيديو إلى الموضوع",
      UploadNewVideo: "تحميل فيديو جديد",
      RemoveQuestion: "إزالة السؤال",
      AllLevels: "جميع المستويات في هذا الموضوع",
      AllUnits: "جميع الوحدات المضافة إلى هذه المادة",
      QRCode: "رمز QR",
      VideoQRCode: "رمز QR للفيديو: ",
      QRCodeDescription:
        "إنشاء رمز QR لهذا الفيديو لمشاركته مع الطلاب، أو تحديث عنوان الفيديو.",
      VideoURL: "رابط الفيديو",
      DownloadQRCode: "تحميل رمز QR",
      GenerateQRCode: "إنشاء رمز QR",
      UpdateQRCode: "تعديل رمز QR",
      OpenVideo: "فتح الفيديو",
      AddTermsTitle: "إضافة شروط وأحكام",
      AddTermsBody: "إضافة شروط وأحكام جديدة إلى المشروع.",
      EditTermsTitle: "تعديل شروط وأحكام",
      EditTermsBody: "تعديل شروط وأحكام التي تم إضافتها مسبقاً.",
      TermsAndConditionsDescription: "قائمة شروط وأحكام المشروع.",
      updateTerms: "تعديل شروط وأحكام",
      Arabic: "العربية",
      English: "الإنجليزية",
      EditPackage: "تعديل الباقة",
      EditPackageBody: "تعديل الباقة التي تم إضافتها مسبقاً.",
      AddPackage: "إضافة باقة جديدة",
      AddPackageBody: "إضافة باقة جديدة إلى المشروع.",
      PleaseSelectImageFile: "الرجاء اختيار ملف صورة فقط.",
      FlashcardQRCode: "رمز QR لبطاقات الحفظ: ",
      FlashcardQRCodeDescription:
        "إنشاء رمز QR لبطاقات الحفظ هذه لمشاركتها مع الطلاب، أو تحديث عنوان بطاقات الحفظ.",
      FlashCradURL: "رابط بطاقات الحفظ",
      DeviceType: "نوع الجهاز",
      CouponType: "نوع الخصم",
      Color: "لون الخلفية",
      PleaseSelectColor: "الرجاء اختيار لون صالح.",
      OpenLink: "فتح الرابط",
      Reorder: "إعادة الترتيب",
      ReorderTopics: "إعادة ترتيب الموضوعات",
      ReorderUnits: "إعادة ترتيب الوحدات",
      ReorderVideos: "إعادة ترتيب الفيديوهات",
      UpdateOrder: "تحديث الترتيب",
      ActivationCode: "رمز التفعيل",
      VideosURL: "روابط الفيديوهات",
      VideosURLBody: "قائمة روابط الفيديوهات في النظام.",
      Url: "الرابط",
      M3U8: "رابط M3U8",
      EnterM3U8: "أدخل رابط M3U8",
      UpdateVideo: "تعديل الفيديو",
      ReorderFlashcardSections: "إعادة ترتيب بطاقات الحفظ",
      Influencers: "المؤثرين",
      AllInfluencers: "جميع المؤثرين في النظام.",
      AddInfluencer: "إضافة مؤثر",
      EditInfluencer: "تعديل المؤثر",
      EditInfluencerBody: "تعديل المؤثر الذي تم إضافته مسبقاً.",
      AddInfluencerBody: "إضافة مؤثر جديد إلى المشروع.",
      Ads: "الإعلانات",
      ReferredStudentsByInfluencer: "الطلاب المرتبطين بالمؤثر: ",
      AllReferredStudentsByInfluencer: "جميع الطلاب المرتبطين بهذا المؤثر.",
      EnterPercentage: "أدخل النسبة",
      PercentageMustBeGreaterThan0: "يجب أن يكون النسبة أكبر من 0.",
      PercentageMustBeLessThan100: "يجب أن يكون النسبة أصغر من 100.",
      PleaseSelectAnAvatar: "يرجى اختيار صورة رمزية.",
      Earnings: "الإيرادات",
      InfluencerEarnings: "إيرادات المؤثر",
      Support: "محادثات الدعم",
      Secure_Url: "الرابط الآمن",
      EnterSecureUrl: "أدخل الرابط الآمن",
      AllReferredStudentsByCoupon: "جميع الطلاب المرتبطين بالكوبون: ",
      ReferredStudentsByCoupon: "الطلاب المرتبطين بالكوبون: ",
      ReferredBy: "مرشح بواسطة",
    },
  },
};
