export const locale = {
  lang: "en",
  data: {
    MENU: {
      loading: "Loading...",

      SELLING_POINTS: {
        COLLAPSIBLE: "Selling Points",
        LIST: "List",
        PREVIEW: "Preview",
        EDIT: "Edit",
        ADD: "Add",
      },

      Main: "Main",
      DEVICES: "Devices",
      Invoice: "Invoice",
      eCommerce: "eCommerce",
      AppVersions: "App Versions",
      PaymentMethods: "Payment Methods",
      SellingPoints: "Selling Points",
      User: "User",
      Settings: "Settings",
      STUDENTS: "Registered Students",
      Subscription: "Subscriptions",
      Transactions: "Transactions",
      Devices: "Devices",
      Admins: "Admins",
      List: "List",
      Add: "Add",
      Avatars: "Avatars",
      Countries: "Countries",
      Onboardings: "Onboardings",
      Levels: "Levels",
      SchoolTypes: "School Types",
      Subjects: "Subjects",
      Units: "Units",
      ListOfUnitsInSystem: "List of units in the system",
      Description: "Description",
      Quizzes: "Quizzes",
      Results: "Results",
      ResultsForStudent: "Results for student",
      ListOfResults: "List of results in the system",
      FlashcardSections: "Flashcard Sections",
      Documents: "Documents",
      Topics: "Topics",
      Videos: "Videos",
      Events: "Events",
      Categories: "Categories",
      Packages: "Packages",
      Bundles: "Bundles",
      Branches: "Branches",
      TermsAndConditions: "Terms & Conditions",
      PrivacyPolicy: "Privacy Policy",
      FAQs: "FAQs",
      AllAdminsListed: "All admins listed in the system",
      Show: "Show",
      Entries: "entries",
      Search: "Search",
      Delete: "Delete",
      Edit: "Edit",
      FirstName: "First Name",
      EnterFirstName: "Enter first name",
      LastName: "Last Name",
      EnterLastName: "Enter last name",
      Country: "Country",
      SelectCountry: "Select a country",
      PleaseSelectCountry: "Please select a country",
      Avatar: "Avatar",
      PleaseSelectAvatar: "Please select an avatar",
      Email: "Email",
      EnterEmail: "Enter email",
      MobileNumber: "Mobile Number",
      EnterMobileNumber: "Enter mobile number",
      Password: "Password",
      EnterPassword: "Enter password",
      ListAllAvatars: "List all avatars which appear to students to pick from",
      PleaseFillFields: "Please feel free to fill all fields",
      PleaseSelectImage: "Please select image",
      ChooseFile: "Choose file",
      SAVE: "SAVE",
      AllCountriesListed: "All countries listed in the system...",
      Entires: " Entries",
      NameInArabic: "Name in Arabic",
      EnterNameInArabic: "Enter name in Arabic",
      NameInEnglish: "Name in English",
      EnterNameInEnglish: "Enter name in English",
      Code: "Code",
      EnterCode: "Enter code",
      MobileCode: "Mobile Code",
      EnterMobileCode: "Enter mobile code",
      Image: "Image",
      MobileLength: "Mobile Length",
      EnterMobileLength: "Enter mobile length",
      StartsWith: "Starts With",
      EnterMobileStartValues: "Enter mobile start values",
      Onboarding: "Onboarding",
      ListOfOnboardingScreens:
        "List of onboarding screens displayed to the user when they open the app.",
      PleaseFillAllFields: "Please feel free to fill all fields",
      TitleInArabic: "Title in Arabic",
      TitleInArabicPlaceholder: "Title in Arabic...",
      TitleInEnglish: "Title in English",
      TitleInEnglishPlaceholder: "Title in English...",
      FieldRequired: "This field is required!",
      BodyInArabic: "Body in Arabic",
      BodyInArabicPlaceholder: "Body in Arabic...",
      BodyInEnglish: "Body in English",
      BodyInEnglishPlaceholder: "Body in English...",
      Save: "SAVE",
      AllLevelsDescription: "All levels added to the project ...",
      ShowEntries: "Show entries",
      EventType: "Event Type",
      EnterEventType: "Enter event type",
      Branch: "Branch",
      SelectABranch: "Select a branch",
      SchoolType: "School Type",
      SelectSchoolType: "Select a school type",
      PleaseSelectSchoolType: "Please select a school type",
      NameInArabicPlaceholder: "Name in Arabic ...",

      NameInEnglishPlaceholder: "Name in English ...",
      AddSubjectToLevel: "Add Subject to Level",
      Subject: "Subject",
      SelectSubject: "Select a subject",
      PleaseSelectSubject: "Please select a subject!",
      FlashcardSectionsInLevel: "Flashcard Sections in Level ",
      AndSubject: " and Subject",
      AddFlashcardSection: "Add Flashcard Section",
      AllFlashcardSections:
        "All flashcard sections added to this level subject ...",
      ViewFlashcards: "View Flashcards",
      SubjectTopics: "Subject Topics",
      AddTopic: "Add Topic",
      AllTopics: "All topics added to this subject ...",
      LevelBranches: "Level Branches",
      AddBranch: "Add Branch",
      AllBranches: "All branches added to this level ...",
      LevelSubjects: "Level Subjects",
      AddSubject: "Add Subject",
      AllSubjects: "All subjects added to this level ...",
      ViewTopics: "View Topics",
      DocumentsList: "List of documents in the system.",
      Level: "Level",
      All: "All",
      PleaseSelectDocument: "Please select the Document",
      ThisFieldIsRequired: "This field is required!",
      SelectALevel: "Select a level",
      SelectASubject: "Select a subject",
      IsMain: "Is Main",
      SelectAValue: "Select a value",
      Yes: "Yes",
      No: "No",
      IsFree: "Is Free",
      DescriptionInArabic: "Description in Arabic",
      DescriptionInEnglish: "Description in English",
      Title: "School Types",
      ListOfSubjects: "List of subjects in the system.",
      NameInArabicRequired: "This field is required!",
      NameInEnglishRequired: "This field is required!",
      DescriptionInArabicRequired: "This field is required!",
      DescriptionInEnglishRequired: "This field is required!",
      Instructions: "Please feel free ...",
      ListOfQuizzes: "List of quizzes in the system.",

      Questions: "Questions",
      QuizInstructionsFor: "Quiz Instructions for",
      AddInstruction: "Add Instruction",
      AllInstructionsAdded: "All instructions added to this quiz ...",
      QuestionAnswersFor: "Question Answers for",
      AddAnswer: "Add Answer",
      AllAnswersAdded: "All answers added to this question ...",
      AddQuestionToQuiz: "Add Question to Quiz",
      QuestionText: "Question Text ...",
      QuestionTextPlaceholder: "Question Text",
      QuestionType: "Question Type",
      SelectQuestionType: "Select a question type",
      SeekSeconds: "Seek Seconds ...",
      SeekSecondsPlaceholder: "Seek Seconds",
      SelectVideo: "Select a video",
      Video: "Video",
      AddInstructionToQuiz: "Add Instruction to Quiz",
      InstructionTextInArabic: "Instruction Text in Arabic",
      InstructionTextInEnglish: "Instruction Text in English",
      PleaseSelectALevel: "Please select a level",
      SelectASubjectFromThisLevel: "Select a subject from this level",
      PleaseSelectASubject: "Please select a subject",
      TimeLimitInMinutes: "Time Limit (in minutes)",
      TimeLimit: "Time Limit (in minutes)",
      TotalMarks: "Total Marks",
      PassingMarks: "Passing Marks",
      Price: "Price",
      Free: "Free",
      Paid: "Paid",
      SelectAPrice: "Select a price",
      AddAnswerToQuestion: "Add Answer to Question",
      AnswerText: "Answer Text",
      IsCorrect: "Is Correct",
      SelectCorrectAnswer: "Select a correct answer",
      True: "True",
      False: "False",
      Question: "Question",
      Answer: "Answer",
      Name: "Name",

      SelectLevel: "Select a level",
      PleaseSelectLevel: "Please select a level!",
      FlashcardsInSection: "Flashcards in Section",
      AddFlashcard: "Add Flashcard",
      AllFlashcardsInSection: "All flashcards in this section ...",

      Flashcards: "Flashcards",
      FlashcardQRCode: "Flashcard section QR Code",
      FlashcardQRCodeDescription:
        "Generate or edit QR code for this flashcard section",
      FlashCradURL: "Flashcard section URL",
      TopicType: "Topic Type ...",
      SelectTopicType: "Select a topic type",
      Quiz: "Quiz",
      FieldIsRequired: "This field is required!",
      SelectQuiz: "Select a quiz",

      Duration: "Duration",
      TopicVideosFor: "Topic Videos for",
      ListOfVideosInTopic: "List of videos in this topic.",
      ListOfTopicsInSystem: "List of topics in the system.",
      EnterTitleInArabic: "Enter title in Arabic",
      EnterTitleInEnglish: "Enter title in English",
      EnterDescriptionInArabic: "Enter description in Arabic",
      EnterDescriptionInEnglish: "Enter description in English",
      Topic: "Topic",
      SelectTopic: "Select a topic",
      EnterDuration: "Enter duration",
      SelectPrice: "Select a price",

      URL: "URL",
      EnterURL: "Enter URL",
      ListOfVideos: "List of videos in the system",
      ListOfCategories: "List of categories in the system.",
      Icon: "Icon",
      PleaseSelectIcon: "Please select icon",
      Category: "Category",
      SelectACategory: "Select a category",

      PleaseSelectACategory: "Please select a category",
      AddBundleToPackage: "Add Bundle to Package",
      PleaseSelectABundle: "Please select a bundle",
      PriceRequired: "This field is required!",
      PriceCannotBeLessThan0: "Price cannot be less than 0!",
      Discount: "Discount (Percentage %)",
      DiscountRequired: "This field is required!",
      DiscountCannotBeLessThan0: "Discount cannot be less than 0!",
      Bundle: "Bundle",
      SelectABundle: "Select a bundle",
      ArabicDescription: "Arabic Description",
      PleaseAddArabicDescription: "Please add Arabic description!",
      EnglishDescription: "English Description",
      PleaseAddEnglishDescription: "Please add English description!",
      AddInformationToPackage: "Add Information to Package",
      AddSubjectToPackage: "Add Subject to Package",
      PackageBundlesFor: "Package Bundles for",
      AddBundle: "Add Bundle",
      AllBundlesAddedToThisPackage: "All bundles added to this package ...",

      Days: "Days",
      KWD: "KWD",
      PackageInformationFor: "Package Information for",
      AddInfo: "Add Info",
      AllInformationAddedToThisPackage:
        "All information added to this package ...",
      PackageSubjectsFor: "Package Subjects for",
      AllSubjectsAddedToThisPackage: "All subjects added to this package ...",
      ListOfPackagesInTheSystem: "List of packages in the system.",

      Info: "Info",

      DurationInDays: "Duration in Days",
      DurationCannotBeGreaterThan365Days:
        "Duration cannot be greater than 365 days!",
      ListOfBundles: "List of bundles in the system",

      LevelID: "Level ID",
      ListOfBranches: "List of branches",
      AddSellingPoint: "Add Selling Point",
      AddNewSellingPointToSystem: "Add new selling point to the system.",
      Latitude: "Latitude",
      Longitude: "Longitude",
      ArabicCity: "Arabic City",
      EnglishCity: "English City",
      ArabicAddress: "Arabic Address",
      EnglishAddress: "English Address",

      AllSellingPointsInSystem: "All selling points in the system.",
      EditPaymentMethod: "Edit Payment Method",
      EditPaymentMethodToSystem: "Edit payment method to the system.",

      ArabicName: "Arabic Name",
      EnglishName: "English Name",
      UpdatePaymentMethod: "Update Payment Method",
      AllPaymentMethodsInSystem: "All payment methods in the system.",
      AddAppVersion: "Add App Version",
      AddNewAppVersion: "Add new app version to the system.",
      VersionNumber: "Version Number ...",
      Platform: "Platform",
      SelectPlatform: "Select Platform",
      AddAppVersionButton: "Add App Version",

      AllAppVersions: "All app versions in the system.",
      AllDevices: "All devices listed in the system...",

      Send: "Send",
      Notification: "Notification",
      SendNotification: "Send Notification",
      AllTransactionsListed: "All Transactions listed in the system...",

      KD: "K.D",
      Subscriptions: "Subscriptions",
      AllSubscriptionsListed: "All Subscriptions listed in the system...",

      ViewInvoice: "View Invoice",
      app_name: "WAJAD-APP",
      all_rights_reserved: "All rights Reserved",
      title: "COPYRIGHT",
      LOGOUT: "Logout",
      NAME_IN_ARABIC: "Enter name in Arabic",
      NAME_IN_ENGLISH: "Enter name in English",
      CODE: "Enter code",
      MOBILE_CODE: "Enter mobile code",
      IMAGE: "Enter image",
      MOBILE_LENGTH: "Enter mobile length",
      STARTS_WITH: "Enter mobile start values",
      ADD_COUNTRY: "Add Country",
      ADD_SUBSCRIPTION_FOR: "Add Subscription for",
      PLEASE_FILL_ALL_FIELDS: "Please fill all fields",
      PACKAGE: "Select a package",
      BUNDLE: "Select a bundle",
      THIS_FIELD_IS_REQUIRED: "This field is required!",
      PACKAGEE: "Package",
      BUNDLEE: "Bundle",
      MESSAGE: "Message",
      SEND: "Send",
      MESSAGE_REQUIRED: "This field is required!",
      DEVICES_FOR: "Devices for",
      ALL_DEVICES_LISTED_FOR_THIS_STUDENT:
        "All devices listed for this student...",
      SHOW: "Show",
      ENTRIES: "Entries",
      SEARCH: "Search",
      DELETE: "Delete",
      SEND_NOTIFICATION: "Send Notification",
      ABOUT: "About",
      LEVEL: "Level",
      SCHOOL: "School",
      BIRTHDATE: "Birthdate",
      COUNTRY: "Country",
      JOINED: "Joined",
      SUBSCRIPTIONS: "Subscriptions",
      START_DATE: "Start Date",
      END_DATE: "End Date",
      LOGIN_TIME: "Login Time",
      LOGOUT_TIME: "Logout Time",
      LOGGED_IN: "Logged In",
      ALL_STUDENTS: "View all registered students' details in the system",
      FILTER_BY_LEVEL: "Filter by Level",
      ALL: "All",
      PROFILE: "Profile",
      QUIZZES: "Quizzes",
      SUBSCRIPTION: "Subscription",
      SUBSCRIPTIONS_FOR: "Subscriptions for",
      ADD_SUBSCRIPTION: "Add Subscription",
      ALL_SUBSCRIPTIONS: "All Subscriptions of this student",
      DAYS: "Days",
      ID: "ID",
      Mobilee: "Mobile",
      Birthdate: "Birthdate",
      CurrentLevel: "Current level",
      Actions: "Actions",
      Version: "Version",
      UserName: "User name",
      DeviceModel: "Device model",
      Manufacturer: "Manufacturer",
      LoginTime: "Login time",
      DeviceName: "Device name",
      PaymentMethod: "Payment Method",
      Web: "Web",
      IOS: "iOS",
      Android: "Android",
      City: "City",
      Address: "Address",
      Student: "Student",
      Score: "Score",
      CorrectAnswers: "Correct Answers",
      Passed: "Passed",
      LoggedStatus: "Logged Status",
      tdMessage: "Message",
      PaymentID: "Payment ID",
      Package: "Package",
      Amount: "Amount",
      StartDate: "Start Date",
      EndDate: "End Date",
      Status: "Status",
      InvoiceID: "Invoice ID",
      StudentName: "Student Name",
      TotalAmount: "Total Amount",
      Body: "Body",
      Instruction: "Instruction",
      InvoiceDate: "Invoice Date",
      From: "From",
      WajadApp: "Wajad Dashboard",
      KuwaitAddress: "Kuwait, Kuwait City, Al Khalig Al Araby Street",
      Item: "Item",
      Total: "Total",
      To: "To",
      EVENT_QUESTIONS: "Event Questions",
      EventQuestions: "Event Questions",
      EventQuestionsList: "List of event questions for the selected event.",
      EventsList: "List of events in the system.",
      ViewQuestions: "View Questions",
      EventQuestionsAskedBy: "Event Questions Asked By",
      EventQuestionsListStudent:
        "List of event questions asked by this student.",
      EventName: "Event Name",
      Titlee: "Title",

      StartTime: "Start Time",
      EndTime: "End Time",
      ViewInstructions: "View Instructions",
      AddReply: "Add a Reply",
      ViewReplies: "View Replies",
      AddQuestionReply: "Add Question Reply",
      AddReplyToQuestion: "Add Reply to Student Question...",
      Reply: "Reply",
      Visibility: "Visibility",
      Visible: "Visible",
      Hidden: "Hidden",
      Teachers: "Teachers",
      AddTeacher: "Add Teacher",
      AllTeachers: "All Teachers in the system",
      Desc: "Description",
      UploadVideo: "Upload Video",
      UploadVideoBody: "Add new video to the system",
      VideoQuestions: "Video Questions",

      SelectLevelID: "Select a level",
      Unit: "Unit",
      PleaseSelectUnit: "Please select a unit",
      ViewUnits: "View Units",
      Coupons: "Coupons",
      DiscountValue: "Discount Value",
      ExpiryDate: "Expiry Date",
      UsageLimit: "Usage Limit",
      MaxDiscountAmount: "Max Discount Amount",
      DiscountType: "Discount Type",
      Fixed: "Fixed",
      Percentage: "Percentage",
      SelectCouponType: "Select Coupon Type",
      AddCoupon: "Add Coupon",
      CouponsList: "List of coupons in the system.",
      Active: "Active",
      Expired: "Expired",
      RemainingUses: "Remaining Uses",
      SelectUnit: "Select a unit",
      IsFreeTrial: "Is Free Trial",
      AddTitle: "Add new Branch",
      AddBody: "Add new branch to the project.",
      EditTitle: "Edit Branch",
      EditBody: "Edit branch which was added before.",
      AddTitles: "Add new Bundle",
      AddBodys: "Add new bundle to the project.",
      EditTitles: "Edit Bundle",
      EditBodys: "Edit bundle which was added before.",
      AddTitless: "Add new Package",
      AddBodyss: "Add new package to the project.",
      EditTitless: "Edit Package",
      EditBodyss: "Edit package which was added before.",
      AddTitlesss: "Add new Category",
      AddBodysss: "Add new category to the project.",
      EditTitlesss: "Edit Category",
      EditBodysss: "Edit category which was added before.",
      AddTitlessss: "Add new Event",
      AddBodyssss: "Add new event to the project.",
      EditTitlessss: "Edit Event",
      EditBodyssss: "Edit event which was added before.",
      AddTitlesssss: "Add new Video",
      AddBodysssss: "Add new video to the project.",
      EditTitlesssss: "Edit Video",
      EditBodysssss: "Edit video which was added before.",
      AddTitleTopic: "Add new Topic",
      AddBodyTopic: "Add new topic to the project.",
      EditTitleTopic: "Edit Topic",
      EditBodyTopic: "Edit topic which was added before.",
      AddTitleNote: "Add new Document",
      AddBodyNote: "Add new document to the project.",
      EditTitleNote: "Edit Document",
      EditBodyNote: "Edit document which was added before.",
      AddTitleFlashSec: "Add new Flashcards Section",
      AddBodyFlashSec: "Add new flashcards section to the project.",
      EditTitleFlashSec: "Edit Flashcards Section",
      EditBodyFlashSec: "Edit flashcards section which was added before.",
      AddTitleFlash: "Add new Flashcard to section",
      AddBodyFlash: "Add new flashcard to the project.",
      EditTitleFlash: "Edit Flashcard",
      EditBodyFlash: "Edit flashcard which was added before.",
      AddTitleQuiz: "Add new Quiz",
      AddBodyQuiz: "Add new quiz to the project.",
      EditTitleQuiz: "Edit Quiz",
      EditBodyQuiz: "Edit quiz which was added before.",
      AddTitleUnit: "Add new Unit",
      AddBodyUnit: "Add new unit to the project.",
      EditTitleUnit: "Edit Unit",
      EditBodyUnit: "Edit unit which was added before.",
      AddTitleSub: "Add new Subject",
      AddBodySub: "Add new subject to the project.",
      EditTitleSub: "Edit Subject",
      EditBodySub: "Edit subject which was added before.",
      AddTitleSchool: "Add new School Type",
      AddBodySchool: "Add new school type to the project.",
      EditTitleSchool: "Edit School Type",
      EditBodySchool: "Edit school type which was added before.",
      AddTitleLevel: "Add new Level",
      AddBodyLevel: "Add new level to the project.",
      EditTitleLevel: "Edit Level",
      EditBodyLevel: "Edit level which was added before.",
      AddTitleOnb: "Add new Onboarding",
      AddBodyOnb: "Add new onboarding to the project.",
      EditTitleOnb: "Edit Onboarding",
      EditBodyOnb: "Edit onboarding which was added before.",
      AddTitleCount: "Add new Country",
      AddBodyCount: "Add new country to the project.",
      EditTitleCount: "Edit Country",
      EditBodyCount: "Edit country which was added before.",
      AddTitleAva: "Add new Avatar",
      AddBodyAva: "Add new avatar to the project.",
      EditTitleAva: "Edit Avatar",
      EditBodyAva: "Edit avatar which was added before.",
      AddTitleAd: "Add new Admin",
      AddBodyAd: "Add new admin to the project.",
      EditTitleAd: "Edit Admin",
      EditBodyAd: "Edit admin which was added before.",
      Addvid: "Add Video Question...",
      Addvideo: "Add Video",
      Replyvid: "Add Reply to Video Question",
      video_questions: "Video Questions",
      add_video_question: "Add Video Question",
      all_video_questions: "All Video Questions in the system",
      student_name: "Student Name",
      EditCoupon: "Edit Coupon",
      EditCouponAdd: "Edit the coupon which was added before.",
      AddCouponProject: "Add a new coupon to the project.",
      VideoQuestFor: "Video Questions for",
      listOfQuest: "List of Questions for video",
      start_date: "Start Date",
      end_date: "End Date",
      add_event: "Add Event",
      live: "Live",
      test: "Test",
      KeepVideo: "Keep Same Video",
      TeacherOptional: "Teacher (optional)",
      SelectTeacher: "Select a teacher",
      EditTeacher: "Edit Teacher",
      EditTeacherBody: "Edit teacher which was added before.",
      AddTeacherBody: "Add new teacher to the project.",
      SelectACountry: "Select a country",
      PleaseSelectACountry: "Please select a country",
      Phone: "Phone",
      EnterPhone: "Enter phone number",
      PleaseEnterAValidEmail: "Please enter a valid email",
      ReferralCode: "Referral Code",
      ReferredStudents: "Referred Students",
      ViewStats: "View Stats",
      ReferredStudentsBy: "Referred Students by Teacher: ",
      AllReferredStudents:
        "All referred students in the system for this teacher.",
      TotalDiscount: "Total Discount",
      TotalEarnings: "Total Earnings",
      Students: "Students",
      Revenue: "Revenue",
      Statistics: "Statistics",
      Available: "Available",
      Used: "Used",
      BestSellerBundles: "Best Seller Bundles",
      BasedOnSubscriptions: "Based on number of subscriptions",
      BundleDiscount: "Bundle Discount",
      CouponDiscount: "Coupon Discount",
      Dashboard: "Dashboard",
      AddQuestion: "Add Question",
      Answers: "Answers",
      VideoURLEmpty: "Empty this field if you want to upload a new video.",
      Back: "Back",
      UploadComplete: "Video Upload Completed Successfully",
      UploadFailed: "Video Upload Failed",
      UploadFailedMsg: "Video Upload Failed",
      UploadCompleteMsg: "Video Upload Completed Successfully",
      QuickVideoAccess: "Quick Video Access",
      AddVideoToTopic: "Add Video to Topic",
      UploadNewVideo: "Upload New Video",
      RemoveQuestion: "Remove Question",
      AllLevels: "All Levels",
      AllUnits: "All Units added to this subject",
      QRCode: "QR Code",
      VideoQRCode: "QR code for video: ",
      QRCodeDescription:
        "Generate a QR code for this video to share it with students, or update the video URL.",
      VideoURL: "Video URL",
      DownloadQRCode: "Download QR Code",
      GenerateQRCode: "Generate QR Code",
      UpdateQRCode: "Update QR Code",
      OpenVideo: "Open Video",
      AddTermsTitle: "Add Terms and Conditions",
      AddTermsBody: "Add new terms and conditions to the project.",
      EditTermsTitle: "Edit Terms and Conditions",
      EditTermsBody: "Edit terms and conditions which was added before.",
      TermsAndConditionsDescription:
        "List of terms and conditions in the project.",
      updateTerms: "Update Terms and Conditions",
      Arabic: "Arabic",
      English: "English",
      EditPackage: "Edit Package",
      EditPackageBody: "Edit package which was added before.",
      DeviceType: "Device Type",
      CouponType: "Coupon Type",
      Color: "Background Color",
      PleaseSelectColor: "Invalid color format, please select a valid color.",
      OpenLink: "Open Link",
      Reorder: "Reorder",
      ReorderTopics: "Reorder Topics",
      ReorderUnits: "Reorder Units",
      ReorderVideos: "Reorder Videos",
      UpdateOrder: "Update Order",
      AddPackage: "Add Package",
      AddPackageBody: "Add new package to the project.",
      ActivationCode: "Activation Code",
      VideosURL: "Videos URLs",
      VideosURLBody: "List of videos URLs in the system.",
      Url: "URL",
      M3U8: "M3U8 URL",
      EnterM3U8: "Enter M3U8 URL",
      UpdateVideo: "Update Video",
      ReorderFlashcardSections: "Reorder Flashcard Sections",
      Influencers: "Influencers",
      AllInfluencers: "All Influencers in the system.",
      AddInfluencer: "Add Influencer",
      EditInfluencer: "Edit Influencer",
      EditInfluencerBody: "Edit influencer which was added before.",
      AddInfluencerBody: "Add new influencer to the project.",
      Ads: "Ads",
      ReferredStudentsByInfluencer: "Referred Students by Influencer: ",
      AllReferredStudentsByInfluencer:
        "All referred students by this influencer.",
      EnterPercentage: "Enter Percentage",
      PercentageMustBeGreaterThan0: "Percentage must be greater than 0.",
      PercentageMustBeLessThan100: "Percentage must be less than 100.",
      PleaseSelectAnAvatar: "Please select an avatar.",
      Earnings: "Earnings",
      InfluencerEarnings: "Influencer Earnings",
      Support: "Support Chats",
      Secure_Url: "Secure URL",
      EnterSecureUrl: "Enter Secure URL",
      AllReferredStudentsByCoupon: "All referred students by this coupon",
      ReferredStudentsByCoupon: "Referred Students by Coupon: ",
      ReferredBy: "Referred By",
    },
  },

};


